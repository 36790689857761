
import {defineComponent, ref, computed, onMounted } from 'vue';
import { store, useStore } from '@/store';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import Tree from "./tree/Tree.vue"
import {ApplicabilityParameters} from "@/components/types/enums";
import {CardArticleMutationTypes} from "@/store/modules/article-card/mutation-types";
import {MessageModalMutationTypes} from "@/store/modules/message-modal/mutation-types";
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import SelectionSlot from '@/components/modals/SelectionSlot.vue'
import Spinner from '@/components/Spinner.vue';
import router from "@/router";
import cross from "@/services/cross";
import params from "@/services/params";
import crossApplicab from "@/services/crossApplicab";
import CompletenessModal from '@/components/modals/CompletenessModal.vue';
import AnalogItemConfirmed from './AnalogItemConfirmed.vue';
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';
import SuitableTree from '@/components/article-card/confirmed-blocks/SuitableTree.vue';
import BaseTable from '@/components/ui/BaseTable.vue';
import BaseBadge from '@/components/ui/BaseBadge.vue';
import BaseError from "@/components/ui/BaseError.vue";

export default defineComponent({
  name: 'ConfirmedWrapper',
  emits: ['edit-item', 'isInfoLoading', 'setCross', 'setColumnCrosses', 'unsetColumnCrosses'],
  components: {
    BaseError,
    BaseBadge,
    BaseTable,
    SuitableTree,
    CompletenessModal,
    BaseIcon,
    Tree,
    ModalWrapper,
    SelectionSlot,
    Spinner,
    BaseCheckbox,
    AnalogItemConfirmed,
    DropdownEllipsis
  },
  props: {
    checkedColumnCrosses: {
      type: Array,
      default: () => []
    },
    content: {
      type: Object,
      default: () => null
    },
    checkedCrosses: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    isEditCrosses: false as any,
    currentCross: null as any,
    checkedColumn: [],
  }),
  computed: {
    headers() {
      return [
        {
          title: 'Марка',
          content: (item: any) => item.brand
        },
        {
          title: 'Модель',
          content: (item: any) => item.model
        },
        {
          title: 'V двигателя',
          content: (item: any) => item.volume
        },
        {
          title: 'Тип топлива',
          content: (item: any) => item.fuelType
        },
        {
          title: 'Мощность',
          content: (item: any) => item.kw && item.hp
            ? `${item.kw} kW / ${item.hp} PS`
            : null,
        },
        {
          title: 'Выпуск',
          content: (item: any) => item.vehYears
        },
        {
          title: 'Тип кузова',
          content: (item: any) => item.bodyType
        },
        {
          title: 'Модификация',
          content: (item: any) => item.modBodyNum
        },
        {
          title: 'Код двигателя',
          content: (item: any) => item.motorCode
        },
      ]
    },
    confirmedContent(): any {
      return this.content.confirmed.slice(
        (this.pageable.page) * 100,
        (this.pageable.page) * 100 + 100
      )
    },
    getApplicabilityBody() {
      return (res: any) => {
        let result = [] as any;
        if (this.content.confirmed.length > 99) {
          this.moreThan99 = true
        } else {
          this.moreThan99 = false
        }

        let converted = this.content.confirmed
          .slice((this.pageable.page) * 100, (this.pageable.page) * 100 + 100)
          .map((o: any) => {
            return [
              o.brand, // Марка
              o.model, // Модель авто
              o.volume, // V двигателя
              o.fuelType, // Тип топлива
              o.kw && o.hp ? `${o.kw} kW / ${o.hp} PS` : null, // Мощность
              o.vehYears, // Выпуск
              o.bodyType, // Тип кузова
              // o.vehTerm, // Модификация
              o.modBodyNum, // Модификация
              o.motorCode, // Модификация
              // o.attributes.length, // Параметры
              o // Удаление
            ]
        });

        converted.forEach((o: any) => {
          // > 1, т.к. всегда присутствует поле для удаления
          let check = o.filter((i: any) => i).length > 1;
          if (check) result.push(o)
        });

        return result
      }
    }
  },
  methods: {
    setCheckedCross(cross: any) {
      this.$emit('setCross', cross)
    },
    async addLogisticParam(param: any) {
      try {
        const newParam = await params.setLogistic({
          paramIds: [param.paramId],
          logistic: true
        })

        param.param = newParam[0]
        store.commit(CardArticleMutationTypes.UPDATE_PARAMETER_VALUE, {key: 'CROSS', value: param});
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    },
    async changeVisible(param: any, status: any) {
      try {
        const isVisible = status === 'VISIBLE'
        const updatedParam = await params.updateVisibleOneParam(param.paramId, isVisible)
        param.param = updatedParam
      } catch (error) {
        console.error(error)
      }
    },
    copyText() {
      const analogArticleRef = this.$refs.analogArticleRef as any;
      navigator.clipboard.writeText(analogArticleRef.innerHTML)
    },
    replaceCross(newCross: any) {
      const indexReplacedCross = this.content.confirmed.findIndex((cross: any) => {
        return cross.id === newCross.id
      })

      this.content.confirmed.splice(indexReplacedCross, 1, newCross)
    },
    closeCompletenessModal() {
      let element = document.getElementById(`modal-completeness`)!
      element.style.display = 'none'
      this.currentCross = null
    },
    openModalByCompleteness(cross: any) {
      this.currentCross = cross
      setTimeout(() => {
        let element = document.getElementById(`modal-completeness`)!
        element.style.display = 'flex'
      }, 0)
    },
    checkAll() {
      this.checkedAll = !this.checkedAll;
      if (this.checkedAll) {
        this.checked = [];
        this.content.confirmed.forEach((v: any) => {
          this.checked.push(v.id)
        });
      } else {
        this.checked = []
      }
    },
    setChecked(item: any) {
      let index = this.checked.findIndex((o: string) => o === item);

      if (index !== -1) {
        this.checked.splice(index, 1);
        this.checkedAll = false
      } else {
        this.checked.push(item);
        if (this.content.confirmed.length === this.checked.length) {
          this.checkedAll = true
        }
      }
    },
    async setDefaultQueueForParameters() {
      const { key, view, confirmed: parameters } = this.content
      const productGroup = this.article?.productGroup

      if (key === 'PARAMS' && view === 'CONFIRMED' && parameters.length && productGroup) {
        const DEFAULT_VALUE_QUEUE = 1000
        const isEveryParamQueueNumberEqual = parameters.every(({param}: any) => {
          return param.queueNumber === DEFAULT_VALUE_QUEUE
        })

        if (isEveryParamQueueNumberEqual) {
          const payload: Record<string, any> = {}

          parameters.map(({ param }: any, index: number) => {
            payload[param.id] = index
          })
          console.log('payload', payload)
          await params.setQueue(productGroup.id, payload)
        }
      }
    }
  },
  setup(props, {emit}) {
    const store = useStore();
    const getRandomId = computed(() => Math.random().toString(36).substring(2));
    const selectedItem: { [x: string]: any } = ref({}); // Элемент для удаления (кроссировка, параметры)
    const tabs = ref([{name: 'Жёсткие', key: 'linkHard'}, {name: 'OE', key: 'OE'}, {name: 'Аналоги', key: 'ABCP'}]);
    const article = computed(() => store.getters.getArticleCard);
    const getParamApplicability = computed(() => store.getters.getParamApplicability) as any;
    const checkUserRoleForEditCardArticle = computed(() => {
      return store.getters.getAllPermissions.edit_article[store.getters.getUserRole]
    })
    const allProductGroupParams = ref([])

    const checkUserRoleForDeleteCrosses = computed(() => {
      return store.getters.getAllPermissions.delete_crosses[store.getters.getUserRole]
    })

    const checked: { [x: string]: any } = ref([]);
    const checkedAll = ref(false as boolean);
    const isLoading = ref(false);
    const moreThan99 = ref(false);
    // const pageable: { [x: string]: any } = computed(() => store.getters.getVehiclePageable);
    const pageable = ref({page: 0, size: 20});

    const getRequiredParams = computed(() => {
      const requiredToAdd: any[] = []

      allProductGroupParams.value.filter((pg: any) => {
        if (pg.mandatory) {
          const pgAlreadyAdded = props.content.confirmed.findIndex((confirmedPg: any) => confirmedPg.param.id === pg.id) >= 0

          if (!pgAlreadyAdded) {
            requiredToAdd.push(pg)
          }
        }
      })

      return requiredToAdd
    })

    const getTree = computed(() => {
      return (tree: any) => {
        const comments = tree.branches
        const nest = (items: any, id = null) => {

          return items
            .filter((item: any) => item.parentId === id)
            .map((item: any) => ({
              ...item,
              children: nest(items, item.articleId)
            }))
        }


        return nest(comments)
      }
    });

    const getAnalogs = computed(() => {
      let values: any = props.content.confirmed;
      let found = values.find((o: any) => o.articleId === article.value.articleId);
      if (found) {
        let array = values.filter((o: any) => o.articleId !== article.value.articleId);
        return [{...found}, ...array];
      } else {
        return []
      }
    });

    const getCross = computed(() => {
      return (tab: string | null) => {
        let result = [];
        if (tab) {
          switch (tab) {
            case 'linkHard':
              result = props.content.confirmed.filter((o: any) => (o.direction === 'CM' || o.direction === 'ABCP') && o.supplier === 'LOCAL');
              break;
            case 'OE':
              result = props.content.confirmed.filter((o: any) => o.direction === 'OE');
              break;
            case 'ABCP':
              result = props.content.confirmed.filter((o: any) => o.direction === 'ANALOG');
              break;
            default:
              break;
          }
        }
        result.sort(function (a: any, b: any) {
          if (a.brand < b.brand) return -1;
          if (a.brand > b.brand) return 1;
          return 0
        });
        return result
      }
    });

    const getApplicabilityHeader = computed(() => Object.values(ApplicabilityParameters));

    function unsetColumnCrosses(tab: any) {
      const crosses = getCross.value(tab.key)
      emit('unsetColumnCrosses', crosses, tab.key)
    }
    function setColumnCrosses(tab: any) {
      const crosses = getCross.value(tab.key)
      emit('setColumnCrosses', crosses, tab.key)
    }

    function checkFocusedCrosses(tab: any) {
      const crosses = getCross.value(tab.key)
      const focusedCrosses = crosses.filter((cross: any) => cross.focus)
      emit('setColumnCrosses', focusedCrosses, tab.key)
    }
    function checkUnfocusedCrosses(tab: any) {
      const crosses = getCross.value(tab.key)
      const focusedCrosses = crosses.filter((cross: any) => !cross.focus)
      emit('setColumnCrosses', focusedCrosses, tab.key)
    }

    function previous() {
      if (pageable.value.page) {
        pageable.value.page -= 1
      }
    }

    function next() {
      if (pageable.value.page + 1 !== Math.ceil(props.content.confirmed.length/100)) {
        pageable.value.page += 1
      }
    }

    async function getTCDC(item: any) {
      emit('isInfoLoading', true)
      item.crossInfo = true;
      item.isDropdownRevealed = false;

      cross.findCrossInfo(item.brandArt, item.brandId).then(response => {
        response.forEach((o: any) => {
          store.commit(CardArticleMutationTypes.SET_TCDC, o)
        });
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
        emit('isInfoLoading', false);
      }).catch(error => {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
        emit('isInfoLoading', false);
        throw error
      })
    }

    async function setFocusCross(item: any) {
      item.isDropdownRevealed = false;
      await cross.setFocusCross(item.id).then(async _ => {
        store.commit(CardArticleMutationTypes.UPDATE_PARAMETER_VALUE, {key: 'CROSS', value: item.id});
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
      }).catch(error => {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
        throw error
      })
    }

    function animation() {
      store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
    }

    function editAnalog(item: any) {
      if (item.article.id !== article.value.articleId) emit('edit-item', item)
    }

    function openDeleteModal(item: any) {
      item.isDropdownRevealed = false;
      selectedItem.value = item;
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'flex'
    }

    function closeDeleteModal() {
      let element = document.getElementById(`modal-${getRandomId.value}`)!;
      element.style.display = 'none'
    }

    function deleteItem() {
      closeDeleteModal();
      setTimeout(() => {
        let payload = {key: props.content.key, value: selectedItem.value.id};
        let promises = [];
        if (props.content.key === 'CROSS') promises.push(cross.deleteCross(selectedItem.value.id));
        else if (props.content.key === 'PARAMS') promises.push(params.deleteParameter(selectedItem.value.id));
        else promises.push(crossApplicab.deleteVehicle(selectedItem.value.id));
        Promise.all(promises).then(_ => {
          store.commit(CardArticleMutationTypes.DELETE_PARAMETER_VALUE, payload);
          store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
          selectedItem.value = null
        }).catch(error => {
          selectedItem.value = null;
          throw error
        })
      }, 500)
    }

    async function deleteSomeApplicability() {
      isLoading.value = true;

      if (checkedAll.value && getParamApplicability.value.confirmed.length === getParamApplicability.value.duplicateConfirmed.length) {
        await crossApplicab.deleteAllVehicles(article.value.articleId);
        store.commit(CardArticleMutationTypes.DELETE_ALL_PARAMETER_VALUE, {
          key: "APPLICABILITY"
        });
      } else if (!!checked.value.length) {
        await crossApplicab.deleteVehicle(checked.value.join(',')).then(_ => {
          if (checked.value.length < 999) {
            checked.value.forEach((value: any) => {
              store.commit(CardArticleMutationTypes.DELETE_PARAMETER_VALUE, {
                key: "APPLICABILITY",
                value
              });
            });
          } else {
            router.go(0)
          }
        })
      }

      isLoading.value = false
      checkedAll.value = false
      checked.value = []
    }

    function sortByFocus(a: any, b: any) {
      if (a.focus < b.focus) {
        return 1;
      }
      if (a.focus > b.focus) {
        return -1;
      }
      return 0;
    }

    function goToVehicle(item: any) {
      router.push({name: 'vehicle', params: {carId: item.vehicleId}})
    }

    onMounted(async  () => {
      const pgId = store.getters.getArticleCard?.productGroup.id

      if (pgId) {
        allProductGroupParams.value = await params.getAllParamsByProductGroup(pgId)
      }
    })

    return {
      getRequiredParams,
      unsetColumnCrosses,
      setColumnCrosses,
      getRandomId,
      tabs,
      article,
      checkUserRoleForEditCardArticle,
      getTree,
      getAnalogs,
      getCross,
      checked,
      checkedAll,
      isLoading,
      moreThan99,
      pageable,
      getApplicabilityHeader,
      getTCDC,
      setFocusCross,
      openDeleteModal,
      closeDeleteModal,
      deleteItem,
      editAnalog,
      deleteSomeApplicability,
      sortByFocus,
      animation,
      previous,
      next,
      goToVehicle,
      checkUserRoleForDeleteCrosses,
      checkUnfocusedCrosses,
      checkFocusedCrosses,
    }
  },
  watch: {
    'content.key'() {
      this.setDefaultQueueForParameters()
    }
  },
  async mounted() { 
    this.setDefaultQueueForParameters() 
  },
})
