
import { computed, defineComponent, onMounted, ref, watch, } from 'vue';
import { useStore } from '@/store';
import { useRoute } from 'vue-router';
import BaseInput from '@/components/ui/BaseInput.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseError from '@/components/ui/BaseError.vue';
import articles from '@/services/articles';
import { CardArticleMutationTypes } from '@/store/modules/article-card/mutation-types';
import Spinner from '@/components/Spinner.vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import router from '@/router';
import HistoryWrapper from '@/components/article-card/history/HistoryWrapper.vue';
import OzonParamsWrapper from '@/components/article-card/ozon/OzonParamsWrapper.vue';
import { AuthenticationMutationTypes } from '@/store/modules/authentication/mutation-types';
import OzonLinkWrapper from '@/components/article-card/OzonLinkWrapper.vue';
import ParamsWrapper from './ParamsWrapper.vue';
import SideBar from './SideBar.vue';
import routerService from '@/services/routerService';
import SelectElementWithContentModal from '../modals/SelectElementWithContentModal.vue';
import ArticleDocumentsTable from '@/components/article-card/ArticleDocumentsTable.vue';
import MarketplaceLinksView from '@/components/article-card/MarketplaceLinksView.vue';
import ArticleVideosTable from '@/components/article-card/ArticleVideosTable.vue';
import LogisticParams from '@/components/article-card/LogisticParams.vue';
import AttributesView from '@/components/article-card/attributes/AttributesView.vue';
import { Roles, RolesEnum, Status } from '@/components/types/enums';
import certificates from '@/services/certificates';
import ArticleCertificate from '@/components/article-card/ArticleCertificate.vue';
import ImageView from '@/components/article-card/ImageView.vue';
import { CardArticleActionTypes } from '@/store/modules/article-card/action-types';
import { IDataSlider, IDataViewArticleCard } from '@/models/VehicleModel';
import AddImagesForOzonModal from '@/components/modals/AddImagesForOzonModal.vue';
import  { AxiosError } from 'axios';

export default defineComponent({
  name: 'CardWrapper',
  components: {
    ArticleCertificate,
    AddImagesForOzonModal,
    ImageView,
    LogisticParams,
    MarketplaceLinksView,
    ArticleVideosTable,
    ArticleDocumentsTable,
    HistoryWrapper,
    BaseInput,
    BaseSelect,
    SideBar,
    BaseIcon,
    BaseError,
    ParamsWrapper,
    Spinner,
    SearchDropdown,
    OzonLinkWrapper,
    OzonParamsWrapper,
    SelectElementWithContentModal,
    AttributesView,
},
  setup() {
    const store = useStore();
    const route = useRoute();
    // const article = computed(() => store.getters.getArticleCard);
    const article = ref(null) as any;
    const activeTab = computed(() => store.getters.getActiveTabCard);
    const params = computed(() => store.getters.getParamsCard);
    const originalStatus = ref(null); // Изначальный статус Артикула, для корректной работы select
    const originalArticle = ref(null); // Изначальный Артикул, для корректной работы select
    const editMode = ref(false as boolean); // Режим редактирования
    const saveWithOldArticle = ref(false as boolean); // СОхранить со старым артикулом
    const errors = ref([] as any); // Ошибки
    const isInfoLoading = ref(false);

    const isOpenSideBarMobile = ref(false as boolean);
    function openSideBarMobile() {
      isOpenSideBarMobile.value = !isOpenSideBarMobile.value
    }

    const convertEditDate = computed(() => {
      if (article && article.value && article.value.editDate) {
        return new Date(article.value.editDate).toLocaleDateString('en-US');
      }
    });
    const getCurrentTabByRoute = computed(() => route.query?.tab);
    const user = computed(() => store.getters.getLoggedUser);
    const loggedUser = localStorage.getItem('loggedUser');
    const cid = localStorage.getItem('cid');

    if (loggedUser && cid && !user.value) {
      store.commit(AuthenticationMutationTypes.SET_USER, JSON.parse(loggedUser));
    }

    const articleDataView = computed(() => store.getters.articleDataView)

    const checkUserRoleForEditCardArticle = computed(() => store.getters.getAllPermissions.edit_article[store.getters.getUserRole]);

    const checkUserRoleForAnalysisPrices = computed(() => store.getters.getAllPermissions.request_prices_for_article[store.getters.getUserRole]);

    const checkUserCanEditStatusArticle = computed(() => store.getters.getAllPermissions.edit_article_status[store.getters.getUserRole]);

    const showByUserRole = computed(() => store.getters.getAllPermissions.edit_article[store.getters.getUserRole]);
    const logisticParams = computed(() => params.value.find((param: any) => param.key === 'PARAMS'))

    const disabledInput = computed(() => (item: string) => {
      switch (item) {
        default:
          return false;
      }
    });

    const updatePG = (productGroupData: any, type: any) => {
      if (type === 'PRODUCT_GROUP') {
        article.value.productGroup = productGroupData;
        if (article.value.productSubGroup && article.value.productSubGroup.desc) {
          article.value.productSubGroup.desc = '';
          article.value.productSubGroup.id = null;
        }

        if (article.value.productSubSubGroup && article.value.productSubSubGroup.name) {
          article.value.productSubSubGroup.name = '';
          article.value.productSubSubGroup.id = null;
        }
      }
      if (type === 'PRODUCT_SUB_GROUP') {
        article.value.productSubGroup = productGroupData;

        if (article.value.productSubSubGroup && article.value.productSubSubGroup.name) {
          article.value.productSubSubGroup.name = '';
          article.value.productSubSubGroup.id = null;
        }
      }
      if (type === 'PRODUCT_SUB_SUB_GROUP') {
      }
    };

    watch(() => route.params.id, async (val) => {
      if (val) {
        article.value = null
        await setDefaultData();
      }
    });

    async function deleteArticleImage(view: IDataSlider, file: string) {
      await store.dispatch(CardArticleActionTypes.DELETE_IMAGE, {
        articleId: article.value.articleId,
        key: view.key,
        file
      })
    }

    async function addArticleImage(view: IDataViewArticleCard, files: FileList) {
      await store.dispatch(CardArticleActionTypes.ADD_IMAGE, {
        files,
        view,
        articleId: article.value.articleId,
      })
    }

    function setApplicabilityTab() {
      const item = params.value.find((o: any) => o.key === 'APPLICABILITY');
      store.commit(CardArticleMutationTypes.SET_ACTIVE_TAB, item);
    }

    function clearState() {
      store.commit(CardArticleMutationTypes.CLEAR_STATE, null);
    }

    function setInfoLoading(item: any) {
      isInfoLoading.value = item;
    }

    async function changeStatus(nextStatus: any) {
      article.value.status = nextStatus
    }

    function openModalOtherImages() {
      const element = document.getElementById(`modal-add-image-ozon`)!;
      element.style.display = 'flex';
    }

    async function updateArticle() {
      errors.value = [];
      setDefaultStyle('article-article');
      setDefaultStyle('article-category');
      setDefaultStyle('article-type');
      setDefaultStyle('name-article');
      const payload: { [x: string]: any } = {};

      const dto: { [x: string]: any } = {
        barcodes: article.value.barcodes, // штрихкод
        articleId: article.value.articleId, // id Артикула
        article: article.value.article, // Артикул (название)
        status: article.value.status, // Статус
        category: article.value.category, // Категория (обязательное поле)
        saleType: article.value.saleType, // Тип артикула
        complex: article.value.complex, // Вид. Примечание: нет в отображении
        commentPrice: article.value.commentPrice, // Примечание для прайса
        commentArticle: article.value.commentArticle, // Примечание к артикулу
        articleReplaceId: article.value.status === 'REPLACED' && article.value.articleReplace
          ? article.value.articleReplace.id
          : null, // Артикул заменён. Примечание: только для статуса "заменён"
        articleReplace: article.value.articleReplace,
        commentApplic: article.value.commentApplic, // Применяемость для прайса
        productGroupId: article.value.productGroup ? article.value.productGroup.id : null, // Товарная группа
        productSubGroupId: article.value.productSubGroup ? article.value.productSubGroup.id : null, // Подгруппа
        name: article.value.name, // Наименование
        productSubSubGroupId: article.value.productSubSubGroup ? article.value.productSubSubGroup.id : null, // Подгруппа 2
        code: article.value.code,
        note: article.value.note,
      };

      Object.keys(dto).forEach((o) => {
        payload[o] = dto[o];
      });

      // Проверки

      if (!payload.name || !payload.name.length) {
        errors.value.push({
          key: 'name-article',
          text: '<span style="font-weight: 500;">Поле <span style="color: #4F5058;">"Наименование"</span> является обязательным для заполнения.<b>',
        });
        setWrongStyle('name-article');
      }

      if (!payload.category || !payload.category.length) {
        errors.value.push({
          key: 'add-category',
          text: '<span style="font-weight: 500;">Поле <span style="color: #4F5058;">"Категория"</span> является обязательным для заполнения.<b>',
        });
        setWrongStyle('article-category');
      }

      if (!payload.saleType || !payload.saleType.length) {
        errors.value.push({
          key: 'add-type',
          text: '<span style="font-weight: 500;">Поле <span style="color: #4F5058;">"Тип"</span> является обязательным для заполнения.<b>',
        });
        setWrongStyle('article-type');
      }

      if (payload.article !== originalArticle.value) {
        if (!payload.article.match(/^[a-zA-Z0-9]/)) { // проверяем первый символ не цифра или буква
          errors.value.push({
            key: 'first-char',
            text: '<span style="font-weight: 500;">Первый символ в названии артикула может содержать символы <span style="color: #4F5058;">A-Z, 0-9</span><b>',
          });
          setWrongStyle('article-article');
        }

        if (!payload.article.match(/[a-zA-Z0-9*]$/)) { // проверяем последний символ не цифра или буква или звездочка
          errors.value.push({
            key: 'last-char',
            text: '<span style=\'font-weight: 500;\'>Последний символ в названии артикула может содержать символы <span style="color: #4F5058;">A-Z, 0-9, \'*\'</span><b>',
          });
          setWrongStyle('article-article');
        }

        if (!payload.article.match(/[a-zA-Z0-9.\-\s\_\*\/]/)) {
          errors.value.push({
            key: 'change-title-article',
            text: '<span style="font-weight: 500;">Название артикула может содержать символы <span style="color: #4F5058;">A-Z, 0-9, "." (точка), " " (пробел), "-" (тире), "_" (нижнее подчеркивание), "*".</span><b>',
          });
          setWrongStyle('article-article');
        }

        if (payload.article.length < 5) {
          errors.value.push({
            key: 'change-title-article-length',
            text: '<span style="font-weight: 500;">Длина названия артикула должна быть не менее 5 символов.<b>',
          });
          setWrongStyle('article-article');
        }
      }

      if (store.getters.getUserRole !== RolesEnum.ADMIN) {
        const statuses: any = Status
        const prevStatus: null | string = originalStatus.value
        const nextStatus = payload.status

        if (prevStatus) {
          //проверяем, чтобы артикул соотвествовал сертификату при смене статуса с времененного на в подготовке
          if (
            statuses[prevStatus] === Status.TEMPORARY &&
            statuses[nextStatus] === Status.PREPARING
          ) {
            const isArticleExist = await store.dispatch('checkCertificateArticle', payload.article)
            if (!isArticleExist) {
              errors.value.push({
                key: 'is-not-exist-article-in-certificate',
                text: '<span style="font-weight: 500;">Артикул не добавлен в сертификат. Выберите другое название артикула.<b>',
              });
              setWrongStyle('article-article');
            }
          }
        }
      }


      if (!errors.value.length) {
        if (originalStatus.value !== payload.status) { // Если мы изменили поле СТАТУС
          if (originalStatus.value === 'TEMPORARY') { // Если в поле статус был статус ВРЕМЕННЫЙ
            if ((originalArticle.value === payload.article || payload.article.toLowerCase().includes('tmp')) && !saveWithOldArticle.value) {
              errors.value.push({
                key: 'change-temp-article',
                text: '<span style="font-weight: 500;">При смене статуса "Временный" необходимо указать новый артикул(не темповый).<b>',
              });
              const el = document.getElementById('article-article');
              if (el) {
                el.style.border = '2px solid #EE1C25';
              }
            } else {
              // не работает метод смены имени и статуса
              const tempPayload = {
                status: payload.status,
                newName: payload.article,
              };

              try {
                await articles.updateArticle(article.value.articleId, payload)
                await articles.changeTempStatus(article.value.articleId, tempPayload)

                originalStatus.value = payload.status;
                editMode.value = false;
                store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
                setDefaultData();
              } catch (error) {
                if (error as AxiosError) {
                  errors.value.push({
                    key: 'required-params',
                    text: `<span style="font-weight: 500;">${error.data.message}<b>`,
                  });
                }

                store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
                throw error;
              }
            }
          } else { // Если в поле статус был любой статус кроме ВРЕМЕННЫЙ
            try {
              await articles.updateArticle(article.value.articleId, payload)
              await articles.changeStatus(article.value.articleId, payload.status)
              originalStatus.value = payload.status;
              editMode.value = false;
              store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
              setDefaultData();
            } catch(error) {
              store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
              throw error;
            }
          }
        } else { // Если мы не меняли статус
          try {
            await articles.updateArticle(article.value.articleId, payload)
            originalStatus.value = payload.status;
            editMode.value = false;
            store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
            setDefaultData();
            errors.value = [];
          } catch(error) {
            store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
            throw error;
          }
        }
      }
    }

    function setWrongStyle(id: string) {
      const el = document.getElementById(id);
      if (el) {
        el.style.border = '2px solid #EE1C25';
      }
    }

    function setDefaultStyle(id: string) {
      const el = document.getElementById(id);
      if (el) {
        el.style.border = 'unset';
      }
    }

    async function setDefaultData() {
      try {
        const result = await articles.getArticleById(Number(route.params.id));
        console.log('Result', result)
        originalStatus.value = result.status;
        originalArticle.value = result.article;

        await store.dispatch(CardArticleActionTypes.FETCH_IMAGES, result.article)

        store.commit(CardArticleMutationTypes.SET_TCDC, {
          params: [],
          oeCrosses: [],
          vehicles: []
        })

        store.commit(CardArticleMutationTypes.SET_ARTICLE, result);
        article.value = store.getters.getArticleCard;

        setViewedArticles(result);
        editMode.value = false;
        errors.value = [];
        setDefaultStyle('article-article');
        setDefaultStyle('article-category');
        setDefaultStyle('article-type');
        setDefaultStyle('name-article');

        await store.dispatch('fetchArticleCertificates', article.value.article)
      } catch (error) {
        router.go(-1);
        throw error;
      }
    }

    function setViewedArticles(item: { [x: string]: any }) {
      let result: { [x: string]: any } = [];
      const storage = JSON.parse(localStorage.getItem('VIEWED_ARTICLES')!);
      if (storage) result = result.concat(storage);
      const found = result.find((o: any) => o.articleId === item.articleId);
      if (found) {
        result.splice(result.indexOf(result.find((o: any) => o.articleId === item.articleId)), 1);
        result.unshift(item);
      }
      if (!found) result.unshift(item);
      if (result.length > 6) result.length = 6;
      localStorage.setItem('VIEWED_ARTICLES', JSON.stringify(result));
    }

    function goToPrices() {
      router.push(`/price/article/${route.params.id}`);
    }

    function openModalSelectPricesAnalyses() {
      const element = document.getElementById(`modal-select-element-with-content`)!;
      element.style.display = 'flex'
    }

    onMounted(async () => {
      const loggedUser = localStorage.getItem('loggedUser');
      const cid = localStorage.getItem('cid');


      if (!(route.query && route.query.tab)) {
        await routerService.setQuery('tab', 'BASIC', route.query, router)
      }

      if (loggedUser && cid) {
        await setDefaultData();
      } else {
        await router.push('/login');
      }
    });

    return {
      changeStatus,
      deleteArticleImage,
      addArticleImage,
      articleDataView,
      openSideBarMobile,
      isOpenSideBarMobile,
      article,
      activeTab,
      params,
      originalStatus,
      originalArticle,
      editMode,
      convertEditDate,
      disabledInput,
      errors,
      saveWithOldArticle,
      isInfoLoading,
      setApplicabilityTab,
      clearState,
      updateArticle,
      setDefaultData,
      setWrongStyle,
      setDefaultStyle,
      goToPrices,
      setInfoLoading,
      checkUserRoleForEditCardArticle,
      checkUserRoleForAnalysisPrices,
      showByUserRole,
      checkUserCanEditStatusArticle,
      updatePG,
      getCurrentTabByRoute,
      openModalSelectPricesAnalyses,
      logisticParams,
      openModalOtherImages
    };
  },
});
